<template>
  <div>
    <b-table
      responsive="sm"
      :fields="fields"
      :items="items"
    >
      <template #cell(batchcategory)="data">
        <b-badge
          pill
          variant="light-primary"
        >
          <small>
            {{ data.item.batchcategory }}
          </small>
        </b-badge>
      </template>
    </b-table>
  </div>
</template>

<script>
import { BTable, BBadge } from 'bootstrap-vue'

// ①batchcategoryなど、固定のkeyで設定する
// ②データを作成する時に希望の列をその固定のkeyと色名を与える
export default {
  components: {
    BTable,
    BBadge,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    header: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Array,
      default: () => [],
    },
    batchKey: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      fields: [],
      items: [],
    }
  },
  watch: {
    header: {
      handler() {
        // 編集する際に既にデータが登録されている場合それを反映する
        if (this.header.length !== 0) {
          this.fields = this.header
        }
      },
      immediate: true,
    },
    value: {
      handler() {
        // 編集する際に既にデータが登録されている場合それを反映する
        if (this.value.length !== 0) {
          this.items = this.value
        }
      },
      immediate: true,
    },
  },
}
</script>
